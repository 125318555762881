/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBw-z1JXOq2WT8wsQcGyhrcDzqAnF-6wRE",
  "appId": "1:280401882825:web:06de3a811ecf28d8446718",
  "authDomain": "schooldog-i-floyd-ga.firebaseapp.com",
  "measurementId": "G-6BEYDK6287",
  "messagingSenderId": "280401882825",
  "project": "schooldog-i-floyd-ga",
  "projectId": "schooldog-i-floyd-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-floyd-ga.appspot.com"
}
